import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/common";
import SEO from "../components/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PostImage from "../components/blog/post-image";


const CategoryPageTemplate = ({ data, context }) => {

  const categories = data.categories.edges
    .map(e => e.node)

  const category = categories[0].title
    
  const posts = categories[0].post
      
  const postsWithTag = Array.from(posts).length

  const title = `${category} category page - ${postsWithTag} post${
    postsWithTag === 1 ? "" : "s"
  } with '${category}'`

  return (
    <Layout>
      <SEO title={title} description={`${title} on Inflection CX Blog`} canonical />

      <header className="pt-24 lg:pt-32 pb-4 lg:pb-6 relative">
        <div className="absolute bg-blue blur-3xl opacity-10 -left-1/3 top-0 w-3/4 h-[70%] transform rotate-6 z-0"></div>
          <div className="container max-w-2xl text-center relative z-10">
          <h1 className="font-semibold text-white">{category}</h1>
          <p className="text-secondary-1 text-xl text-grey-400">{title}</p>
        </div>
      </header>
      
      <section className="sm:pt-10 pt-20">
        <div className="container mx-auto relative pb-10">
          <div className="flex flex-wrap -mx-4">
            {posts.map(i => {
              const postImage =  getImage(i.image)
            return (
              <a 
                href={`/blog/${i.slug}`}
                className="px-4 w-full md:w-1/2 lg:w-1/4 my-6 hover:opacity-80">
                <div className="w-full relative overflow-hidden aspect-w-16 aspect-h-9 bg-cx">
                  <div className="absolute inset-0">
                    {i.image && <PostImage post={i} />}
                  </div>
                </div> 
                <p className="text-accent font-medium mt-4 mb-3 text-white">{i.heading}</p>
                <p className="text-sm font-bold text-grey-400">{i.createdAt}</p>
              </a>
            )}
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default CategoryPageTemplate

export const CategoryPageQuery = graphql`
  query CategoryPagesQuery($id: String!) {
    
    categories: allContentfulCategory(
      filter: {
        id: { eq: $id }
      }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          id
          slug
          title
          post {
            id
            heading
            date(formatString: "MMMM DD yyyy")
            tags {
              slug
              title
              id
            }
            createdAt(formatString: "MMMM DD yyyy")
            slug
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
